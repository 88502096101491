import { APP_INITIALIZER, Injector } from '@angular/core';
import { AuthClientConfig, provideAuth0, AuthService, AuthConfig } from '@auth0/auth0-angular';
import { AUTH_PATH, O8AuthConfig } from '../login.interface';
import { EMPTY, of } from 'rxjs';
import { AuthenticationService } from './authentication.service';
import { APP_CONFIG } from '../../../shared-providers';

export { UserService } from './user.service';

export { canActivatePermissions } from './permissions.route.guard';

export const authConfigInitializer =
  (withPath: string) => (injector: Injector, auth0ClientConfig: AuthClientConfig) => {
    return () => {
      const appConfig = injector.get(APP_CONFIG) as { [withPath: string]: O8AuthConfig };
      const authConfig = {
        useFormData: false, // Will still use application/json content type instead of application/x-www-form-urlencoded which became default in v2: https://github.com/auth0/auth0-angular/blob/v2.0.0/MIGRATION_GUIDE.md
        useRefreshTokens: true,
        useRefreshTokensFallback: true,
        cacheLocation: 'localstorage',
        httpInterceptor: {
          allowedList: [
            {
              uri: 'http://localhost:3333/api',
              allowAnonymous: true,
            },
          ],
        },
        authorizationParams: {
          audience: 'Permission API',
        },
        errorPath: AUTH_PATH.FAIL_FULL_PATH,
        ...appConfig[withPath],
      } as O8AuthConfig;
      auth0ClientConfig.set(authConfig as unknown as AuthConfig);
    };
  };

export const withLoginProviders = (withPath = 'auth') => {
  return [
    ...provideAuth0(),
    {
      provide: APP_INITIALIZER,
      useFactory: authConfigInitializer(withPath),
      deps: [Injector, AuthClientConfig],
      multi: true,
    },
  ];
};

class AuthenticationServerService {
  initO8LoginFlow$ = () => EMPTY;
  getIsLoggedIn$ = () => of(false);
  getIdToken$ = () => of(null);
  getIsSigningIn$ = () => of(false);
  getAccessToken$ = () => of(null);
  getUserPermissions$ = () => of();
  getUserEmail$ = () => of();
  getUserRoles$ = () => of();
  logout = () => of();
  isLoading$ = () => of();
}

export const withSsrLoginProviders = () => [
  {
    provide: AuthService,
    useValue: {
      loginWithRedirect: () => of(),
      logout: () => of(),
      getAccessTokenSilently: () => of(),
      isLoading$: of(false),
      isAuthenticated$: of(true),
      user$: of(null),
      idTokenClaims$: of(),
      handleRedirectCallback: () => of({}),
    },
  },
  {
    provide: AuthenticationService,
    useClass: AuthenticationServerService,
  },
];
